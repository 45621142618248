<template>
  <svg id="Layer_1" role="img" :style="{height: height}" :class="customClass" style="margin-bottom: 0.2em;" enable-background="new 0 0 512.203 512.203"  viewBox="0 0 512.203 512.203" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="m390.537 385.842c47.303 0 85.787-38.484 85.787-85.787 0-19.457-6.698-38.514-18.861-53.658-8.379-10.434-19.124-18.758-31.172-24.303 3.261-10.71 4.908-21.834 4.908-33.232 0-27.334-9.801-53.791-27.597-74.496-14.979-17.429-34.818-29.902-56.752-35.87-2.823-19.661-11.922-37.797-26.274-51.995-17.276-17.09-40.173-26.501-64.474-26.501s-47.199 9.411-64.474 26.5c-14.352 14.198-23.451 32.334-26.274 51.995-21.934 5.968-41.772 18.441-56.752 35.87-17.796 20.705-27.597 47.162-27.597 74.496 0 11.398 1.646 22.522 4.908 33.232-12.048 5.545-22.793 13.869-31.172 24.303-12.163 15.145-18.861 34.201-18.861 53.658 0 47.303 38.484 85.787 85.787 85.787h119.436v96.361h-123.716v30h277.43v-30h-123.715v-96.361h119.435zm-268.871-30c-30.761 0-55.787-25.026-55.787-55.787 0-26.031 17.68-48.357 42.994-54.295l18.042-4.231-7.898-16.765c-5.316-11.285-8.012-23.364-8.012-35.902 0-20.163 7.227-39.675 20.348-54.942 12.989-15.112 30.908-25.174 50.457-28.332l12.457-2.012.15-12.617c.4-33.613 28.072-60.959 61.685-60.959s61.284 27.346 61.684 60.958l.15 12.617 12.457 2.012c19.549 3.158 37.468 13.22 50.457 28.332 13.122 15.268 20.348 34.779 20.348 54.942 0 12.538-2.696 24.617-8.012 35.902l-7.898 16.765 18.042 4.231c25.314 5.938 42.994 28.264 42.994 54.295 0 30.761-25.026 55.787-55.787 55.787h-119.435v-83.429l54.938-54.938-21.213-21.213-33.726 33.725v-99.405h-30v45.299l-33.726-33.725-21.213 21.213 54.938 54.938v65.785l-33.726-33.726-21.213 21.213 54.938 54.938v29.323h-119.433z"/></svg>
</template>
<script>
  export default {
    props: {
      icon: String,
      customClass: {
        type: String,
        default: ''
      },
      color: String,
      height: {
        type: String,
        default: '1.2em'
      }
    },
    computed: {
      trueColor(){
        const predefinedColor = {
          secondary: '#66676c'
        }
        if(typeof this.color !== 'undefined'){
          return (typeof predefinedColor[this.color] !== 'undefined') ? predefinedColor[this.color] : this.color
        }else{
          return '#666000' // default text color
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>